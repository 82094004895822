<template>
    <div>
        <Hero title="Devotional"/>

        <div v-if="initialDataLoad" class="p-8 md:px-40 md:py-10">
            <div class="flex font-bold text-lg uppercase justify-center">This Month</div>

            <div v-for="devotional in devotionals.slice(0, 1)" class="lg:grid lg:grid-cols-2 mt-5">
                <div class="text-xl lg:pe-20 flex flex-col gap-9">
                    <div class="font-bold text-xl md:text-6xl">{{ devotional.data.month }} {{ devotional.data.year }} Devotional</div>

                    <div>
                        The Devotional is designed to help readers grow in their understanding of God's Word, deepen their relationship with God, and foster spiritual growth.
                    </div>

                    <div v-if="devotional.data.contents.length > 0">
                        <div class="font-bold text-lg">Contents</div>
                        <ul>
                            <li v-for="content in devotional.data.contents" class="ms-10">{{ CapitalizeFirstLetter(content) }}</li>
                        </ul>
                    </div>

                    <div class="md:w-fit border rounded px-4 md:px-10 py-4">
                        <div class="break-words">{{ devotional.data.file_name }}</div>
                        <div class="text-slate-500 text-sm"> {{(devotional.data.size / 1000000).toFixed(2) + ' MB'}} </div>
                        <div class="mt-5">
                            <a :href="devotional.data.pdf_file" target="_blank">
                                <PrimaryButton class="max-w-fit text-sm" content="Preview devotional" />
                            </a>
                        </div>
                    </div>

                    <SecondaryButton content="Download" @click="downloadPdf(devotional.data)"/>
                </div>
                <div class="lg:pe-5 pt-5">
                    <img :src="devotional.data.cover_image" :alt="`${devotional.data.cover_image} thumbnail`" class="w-full">
                </div>
            </div>

            <OtherDevotionals @download="downloadPdf" class="mt-32"/>
        </div>
        <div v-else>
            <Loader />
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import Hero from '../components/Devotional/Hero.vue';
import OtherDevotionals from '../components/Devotional/OtherDevotionals.vue'
import SecondaryButton from '../components/SecondaryButton.vue';
import { useDataStore } from '../stores/useData';
import Loader from '../components/Loader.vue';
import PrimaryButton from '../components/PrimaryButton.vue'
import CapitalizeFirstLetter from '../utils/capitalizeFirstLetter.js'

let emits = defineEmits(['downloading'])

let dataStore = useDataStore()
let { devotionals, initialDataLoad } = storeToRefs(dataStore)

async function downloadPdf(pdfUrl) {
  // Fetch the audio data
  fetch(pdfUrl.pdf_file)
  .then(response => {
      const totalSize = response.headers.get('content-length');
      let downloadedSize = 0;

      const readableStream = response.body;

      // Create a new ReadableStream and use the reader to read chunks
      const reader = readableStream.getReader();

      return new ReadableStream({
        start(controller) {
          function read() {
            return reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();
                return;
              }

              downloadedSize += value.byteLength;
              const progress = (downloadedSize / totalSize) * 100;

              emits('downloading', { name: pdfUrl.file_name, progress })

              controller.enqueue(value);
              return read();
            });
          }

          return read();
        }
      });
    })
    .then(stream => new Response(stream))
    .then(response => response.blob())
    .then(blob => {
      const blobURL = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobURL;
      link.download = pdfUrl.file_name;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(blobURL);
    })
    .catch(error => {
        emits('downloading', { name: pdfUrl.file_name, progress: 'failed' })
    });
}
</script>

<style scoped>

</style>